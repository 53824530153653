import { useEffect, useState } from 'react';
import { SearchBar } from './SearchBar';
import { Track } from '../Types/Track';

import TrackCard from './TrackCard';
import '../Styles/TrackCard.css';

function Homepage() {
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const [tracks, setTracks] = useState<Track[]>([]);
	const [trends, setTrends] = useState<Track[]>([]);

	function loadData() {
		setLoading(true);
		const xhr = new XMLHttpRequest();
		xhr.open('GET', `${process.env.REACT_APP_BACKEND}/search?q=${search}`);
		xhr.onload = function () {
			if (xhr.status === 200) {
				setTracks(JSON.parse(xhr.responseText) as Track[]);
			}
			setLoading(false);
		};
		xhr.send();
	}

	function loadTrends() {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', `${process.env.REACT_APP_BACKEND}/trend`);
		xhr.onload = function () {
			if (xhr.status === 200) {
				setTrends(JSON.parse(xhr.responseText) as Track[]);
			}
		};
		xhr.send();
	}

	useEffect(() => {
		loadTrends();
		search && loadData();
	}, [search]);

	return (
		<>
			<SearchBar
				setSearch={setSearch}
				placeholder="Rechercher une musique ...."
			/>
			<div className="tracks-container">
				{tracks.map((track) => (
					<TrackCard key={track.id} track={track} />
				))}
			</div>
			{search === '' && (
				<div className="trends appear-animation">
					<h2 className="trends-title">
						Tendances Youtube en France en ce moment
					</h2>{' '}
					{}
					<div className="trends-container">
						{trends.map((trend) => (
							<TrackCard key={trend.id} track={trend} />
						))}
					</div>
				</div>
			)}
		</>
	);
}

export default Homepage;
