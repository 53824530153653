import React, { useContext, useEffect } from 'react';
import '../Styles/Navbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PopupModalContext } from './PopupModal';
import { useUserStateValue } from './UserReducerProvider';
import logo from '../Ressources/logo.png';

export const Navbar = ({ setLogged }: any) => {
	const { state, dispatch } = useUserStateValue();
	const navigate = useNavigate();
	const location = useLocation().pathname;
	const isAuthenticated = !!localStorage.getItem('token');

	const { openSuccess } = useContext(PopupModalContext);

	if (!state.logged) {
		return null;
	}

	const handleLogout = () => {
		localStorage.removeItem('token');
		dispatch({ type: 'LOGOUT' });
		openSuccess('Vous vous êtes deconnecté avec succès');
	};

	return (
		<div className="Navbar">
			<Link to="/" className="logo">
				<img src={logo} alt="E2MP3" />
			</Link>
			<Link to="/" className={location === '/' ? 'active' : ''}>
				Search
			</Link>
			{isAuthenticated && (
				<>
					<Link
						to="/playlist"
						className={location === '/playlist' ? 'active' : ''}
					>
						Playlist
					</Link>
					<Link
						to="/history"
						className={location === '/history' ? 'active' : ''}
					>
						Historique
					</Link>
				</>
			)}
			<Link to="/premium" className={location === '/premium' ? 'active' : ''}>
				Premium
			</Link>
			{isAuthenticated ? (
				<Link to="/" className="btn connect-button" onClick={handleLogout}>
					DÉCONNEXION
				</Link>
			) : (
				<Link to="/login" className="btn connect-button">
					CONNEXION
				</Link>
			)}
		</div>
	);
};
