import React, { useContext } from 'react';
import '../Styles/PwdResetRequest.css';
import { PopupModalContext } from './PopupModal';
import PasswordService from '../Services/PasswordService';
import { Link } from 'react-router-dom';

function PwdResetRequest() {
	const [accountEmail, setAccountEmail] = React.useState('');

	const { openSuccess } = useContext(PopupModalContext);

	const handleReset = async (e: React.FormEvent) => {
		e.preventDefault();
		await PasswordService.sendResetEmail(accountEmail);
		openSuccess(
			"Si l'adresse mail est liée à un compte, un email de réinitialisation vous sera envoyé.",
		);
	};

	return (
		<div id="Recover" className="appear-animation">
			<form className="recover-form" action="#">
				<div className="back-link">
					<Link to="/login">{'<'} Retour</Link>
				</div>
				<h1 className="header-1">Entrez l'adresse mail liée à votre compte</h1>
				<input
					className="recover-input"
					type="email"
					value={accountEmail}
					onChange={(e) => setAccountEmail(e.target.value)}
					placeholder="Email"
					required
				/>
				<button className="lgn-btn" onClick={handleReset}>
					Envoyer un email de réinitialisation
				</button>
			</form>
		</div>
	);
}

export default PwdResetRequest;
