import React, { useContext, useEffect } from 'react';
import '../Styles/Login.css';
import '../Styles/index.css';
import { Link, useNavigate } from 'react-router-dom';
import LoginService from '../Services/LoginService';
import { ResponseError } from '../Types/Error';
import { PopupModalContext } from './PopupModal';
import { useUserStateValue } from './UserReducerProvider';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import logo from '../Ressources/logo.png';

export const Login = () => {
	const [username, setUsername] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [passwordConfirm, setPasswordConfirm] = React.useState('');
	const [loginPassword, setLoginPassword] = React.useState('');
	const [loginEmail, setLoginEmail] = React.useState('');
	const { openError } = useContext(PopupModalContext);

	const { state, dispatch } = useUserStateValue();
	const navigate = useNavigate();

	useEffect(() => {
		const signUpButton = document.getElementById('signUp');
		const signInButton = document.getElementById('signIn');
		const container = document.getElementById('container');

		signUpButton?.addEventListener('click', () => {
			container?.classList.add('right-panel-active');
		});

		signInButton?.addEventListener('click', () => {
			container?.classList.remove('right-panel-active');
		});
	}, [state.logged]);

	const handleLogin = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!loginEmail || !loginPassword) {
			return;
		}
		await login(loginEmail, loginPassword);
	};

	const login = async (email: string, password: string) => {
		try {
			const loggedData = await LoginService.login(email, password);
			if (loggedData) {
				dispatch({ type: 'LOGIN', payload: loggedData });
				navigate('/');
			} else {
				openError('Une erreur est survenue, reessayez plus tard');
			}
		} catch (resError: ResponseError | any) {
			if (resError.message === 'ERR_USER_NOT_FOUND')
				openError("Aucun compte n'existe avec cet email");
			else if (resError.message === 'ERR_PASSWORD_INCORRECT')
				openError('Le mot de passe pour ce compte est incorrect');
		}
	};

	const handleRegister = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!username || !email || !password || !passwordConfirm) {
			return;
		} else if (password !== passwordConfirm) {
			openError('Les mots de passe de correspondent pas');
		} else {
			try {
				const registered = await LoginService.register(
					username,
					password,
					email,
				);
				if (registered) {
					await login(email, password);
				} else {
					openError('Une erreur est survenue, reessayez plus tard');
				}
			} catch (resError: ResponseError | any) {
				if (resError.message === 'ERR_USER_ALREADY_EXISTS') {
					openError("Ce nom d'utilisateur existe déjà !");
				} else if (resError.message === 'ERR_EMAIL_ALREADY_EXISTS') {
					openError('Il existe déjà un compte à cette adresse mail');
				}
			}
		}
	};

	const responseMessage = async (response: CredentialResponse) => {
		if (response.credential) {
			const decoded: any = jwtDecode(response.credential);
			const { email, name } = decoded;

			try {
				const loggedData = await LoginService.googleLogin(email, name);
				if (loggedData) {
					dispatch({ type: 'LOGIN', payload: loggedData });
					navigate('/');
				} else {
					openError('Une erreur est survenue, reessayez plus tard');
				}
			} catch (resError: ResponseError | any) {
				openError('Une erreur est survenue, reessayez plus tard');
			}
		} else {
			console.log('Google OAuth failed: No credential received');
		}
	};

	const errorMessage = () => {
		console.log('Google OAuth error');
	};

	return (
		<React.Fragment>
			<img src={logo} className="logo-title" />
			<div id="Login" className="appear-animation">
				<div className="container login-register-container" id="container">
					<div className="form-container sign-up-container">
						<form className="login-form" action="#">
							<h1 className="header-1">Créer un compte</h1>
							<input
								className="login-input"
								id="register-username"
								type="text"
								placeholder="Pseudo"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								required
							/>
							<input
								className="login-input"
								id="register-email"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Email"
								required
							/>
							<input
								className="login-input"
								id="register-password"
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder="Mot de Passe"
								required
							/>
							<input
								className="login-input"
								id="register-confirm-password"
								type="password"
								value={passwordConfirm}
								onChange={(e) => setPasswordConfirm(e.target.value)}
								placeholder="Confirmer le mot de Passe"
								required
							/>
							<button
								className="lgn-btn"
								id="register-button"
								onClick={handleRegister}
							>
								S'enregistrer
							</button>
						</form>
					</div>
					<div className="form-container sign-in-container">
						<form className="login-form" action="#">
							<h1 className="header-1">Se connecter</h1>
							<input
								className="login-input"
								type="email"
								value={loginEmail}
								onChange={(e) => setLoginEmail(e.target.value)}
								placeholder="Email"
								required
							/>
							<input
								className="login-input"
								type="password"
								value={loginPassword}
								onChange={(e) => setLoginPassword(e.target.value)}
								placeholder="Mot de Passe"
								required
							/>
							<div className="forgotPwd">
								<Link to="/password-reset">Mot de passe oublié ?</Link>
							</div>
							<button className="lgn-btn" onClick={handleLogin}>
								Se connecter
							</button>
							<p style={{ marginBottom: '20px' }}> OU </p>
							<GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
						</form>
					</div>
					<div className="overlay-container">
						<div className="overlay">
							<div className="overlay-panel overlay-left">
								<h1 className="header-1">Heureux de vous revoir !</h1>
								<p className="paragraph">
									Pour rester connecté avec nous, connecte toi avec tes
									informations.
								</p>
								<button className="lgn-btn" id="signIn">
									Se connecter
								</button>
							</div>
							<div className="overlay-panel overlay-right">
								<h1 className="header-1">Bienvenue !</h1>
								<p className="paragraph">
									Entre tes informations et commence ton voyage avec nous.
								</p>
								<button className="lgn-btn" id="signUp">
									Créer un compte
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
