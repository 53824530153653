import { UserAction, UserState, initialUserState } from '../Types/Reducer';

export function UserReducer(state: UserState, action: UserAction): UserState {
	switch (action.type) {
		case 'LOGIN':
			return {
				logged: true,
				accessToken: action.payload.access_token,
				user: action.payload.user,
				loaded: true,
			};
		case 'LOGOUT':
			return initialUserState;
		case 'DOWNLOAD': {
			const user = state.user;
			if (!user || user.limit <= 0) return state;
			return { ...state, user: { ...user, limit: (user.limit -= 1) } };
		}
		case 'LOADED':
			return { ...state, loaded: true };
		default:
			return state;
	}
}
