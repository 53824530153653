import { createContext, useEffect, useMemo, useState } from 'react';
import '../Styles/PopupManager.css';
import {
	faCheck,
	faCircleXmark,
	faX,
	faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PopupModalContext = createContext({
	hideError: () => {
		return;
	},
	openError: (content: string) => {
		return;
	},
	openSuccess: (content: string) => {
		return;
	},
});

export const PopupModalProvider = ({ children }: any) => {
	const [modalContent, setModalContent] = useState<{
		type: 'error' | 'info' | 'success';
		display: boolean;
		content: string | null;
	}>({ type: 'error', display: false, content: null });

	const hideModal = (
		content: string | null,
		type: 'error' | 'info' | 'success',
	) => {
		setModalContent({ display: false, content, type });
	};

	return (
		<PopupModalContext.Provider
			value={useMemo(
				() => ({
					hideError: () => setModalContent({ ...modalContent, display: false }),
					openError: (content: string) => {
						setModalContent({
							...modalContent,
							content,
							display: true,
							type: 'error',
						});
						setTimeout(() => {
							hideModal(content, 'error');
						}, 5000);
					},
					openSuccess: (content: string) => {
						setModalContent({
							...modalContent,
							content,
							display: true,
							type: 'success',
						});
						setTimeout(() => {
							hideModal(content, 'success');
						}, 5000);
					},
				}),
				[],
			)}
		>
			<div className={`toast ${modalContent.display ? 'active' : ''}`}>
				<div className="toast-content">
					<FontAwesomeIcon
						icon={modalContent.type === 'error' ? faCircleXmark : faCircleCheck}
						className={`icon ${modalContent.type}`}
					></FontAwesomeIcon>

					<div className="message">
						<span className={`text titlePop ${modalContent.type}`}>
							{modalContent.type === 'error' ? 'Erreur' : 'Succès'}
						</span>
						<span className="text content">{modalContent.content}</span>
					</div>
				</div>
				<FontAwesomeIcon
					icon={faX}
					className="close"
					onClick={() => hideModal(modalContent.content, modalContent.type)}
				></FontAwesomeIcon>
				<div
					className={`progress ${modalContent.type} ${
						modalContent.display ? 'active' : 'hide'
					}`}
				></div>
			</div>
			{children}
		</PopupModalContext.Provider>
	);
};
