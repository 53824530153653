import React, { useContext, useEffect, useState } from 'react';
import { SearchBar } from './SearchBar';
import { saveAs } from 'file-saver';

import '../Styles/PlaylistPage.css';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useUserStateValue } from './UserReducerProvider';
import { PopupModalContext } from './PopupModal';
import Loader from './Loader';
import soundcloudLogo from '../Ressources/soundcloud.jpg';
import type { Playlist } from '../Types/Playlist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PlaylistPage() {
	const [search, setSearch] = useState('');
	const [downloading, setDownloading] = useState(false);
	const { state, dispatch } = useUserStateValue();
	const { openError } = useContext(PopupModalContext);
	const token = localStorage.getItem('token');
	const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(
		null,
	);
	const isPremium = state.user && state.user.role === 'premium';

	const download = () => {
		if (!isPremium) {
			openError('Télécharger une playlist est une fonctionnalité premium');
			return;
		}

		const xhr = new XMLHttpRequest();
		xhr.open(
			'GET',
			`${process.env.REACT_APP_BACKEND}/playlist/download?url=${search}`,
		);

		xhr.responseType = 'blob';

		// Set token in request header
		xhr.setRequestHeader('Authorization', `Bearer ${token}`);

		setDownloading(true);

		xhr.onload = () => {
			const blob = xhr.response;
			saveAs(blob, 'playlist.zip');
			dispatch({ type: 'DOWNLOAD' });
			setDownloading(false);
		};
		xhr.send();
	};

	const getPlaylistInfo = () => {
		if (!search) {
			openError('Veuillez entrer un lien de playlist');
			return;
		}
		const xhr = new XMLHttpRequest();
		xhr.open(
			'GET',
			`${process.env.REACT_APP_BACKEND}/playlist/info?url=${search}`,
		);
		xhr.setRequestHeader('Authorization', `Bearer ${token}`);
		xhr.onload = () => {
			try {
				const response = JSON.parse(xhr.responseText) as Playlist;
				setSelectedPlaylist(response);
			} catch (e) {
				openError('Impossible de récupérer les informations de la playlist');
			}
		};
		xhr.send();
	};

	return (
		<div className="playlist-page appear-animation">
			<SearchBar
				setSearch={setSearch}
				placeholder="Lien d'une playlist Youtube ou Soundcloud"
				icon={faDownload}
				onClick={getPlaylistInfo}
				manualConfirm
			/>
			{selectedPlaylist && (
				<div className="playlist-info">
					<div className="playlist-info--body">
						<img
							src={selectedPlaylist.thumbnailUrl ?? soundcloudLogo}
							alt="Playlist thumbnail"
						/>
						<h3>Playlist: {selectedPlaylist.title}</h3>
					</div>

					<button
						className={`download ${downloading ? 'downloading' : ''}`}
						onClick={() => download()}
					>
						<FontAwesomeIcon
							className={downloading ? 'downloading-reverse' : ''}
							icon={faDownload}
						/>
					</button>
				</div>
			)}
			{downloading && (
				<div className="downloading-content">
					<Loader />
					<p>
						Téléchargement de votre playlist ... Cela peut prendre quelques
						temps
					</p>
				</div>
			)}
		</div>
	);
}

export default PlaylistPage;
