import React from 'react';
import { useNavigate } from 'react-router-dom';

function SuccessPage() {
	const navigate = useNavigate();

	return (
		<div className="success-page">
			<h1>Paiement réussi</h1>
			<p>Votre abonnement Premium a été activé avec succès.</p>
			<button className="btn" onClick={() => navigate('/')}>
				Retour à l'accueil
			</button>
		</div>
	);
}

export default SuccessPage;
