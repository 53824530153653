import {
	type IconDefinition,
	faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../Styles/Searchbar.css';
import { useState } from 'react';

interface SearchBarProps {
	setSearch: React.Dispatch<React.SetStateAction<string>>;
	placeholder: string; // Ajouter un prop pour le placeholder
	icon?: IconDefinition;
	onClick?: () => void;
	manualConfirm?: boolean;
}

export const SearchBar = ({
	setSearch,
	placeholder,
	icon,
	onClick,
	manualConfirm,
}: SearchBarProps) => {
	const [searchValue, setSearchValue] = useState('');
	return (
		<div className="search-bar appear-animation">
			<input
				type="text"
				name="Search"
				id="search"
				placeholder={placeholder}
				autoComplete="off"
				onChange={(e) => {
					setSearchValue(e.target.value);
					if (manualConfirm) setSearch(e.target.value);
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setSearch(searchValue);
						if (manualConfirm && onClick) onClick();
					}
				}}
			/>
			<button
				className="search-button"
				onClick={() => (onClick ? onClick() : setSearch(searchValue))}
			>
				<FontAwesomeIcon icon={icon ?? faMagnifyingGlass} />
			</button>
		</div>
	);
};
