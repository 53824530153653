import { createContext, useContext, useEffect, useReducer } from 'react';
import { UserAction, UserState, initialUserState } from '../Types/Reducer';
import { UserReducer } from '../Services/UserReducer';
import { getUser } from '../Services/getUser';

const UserStateContext = createContext<
	{ state: UserState; dispatch: React.Dispatch<UserAction> } | undefined
>(undefined);

export const UserStateProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(UserReducer, initialUserState);
	useEffect(() => {
		getUser()
			.then((user) => {
				if (!user) return dispatch({ type: 'LOADED' });
				dispatch({
					type: 'LOGIN',
					payload: {
						user: user,
						access_token: localStorage.getItem('token') as string,
					},
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}, [state.logged]);
	return (
		<UserStateContext.Provider value={{ state, dispatch }}>
			{children}
		</UserStateContext.Provider>
	);
};

export const useUserStateValue = () => {
	const context = useContext(UserStateContext);
	if (!context) {
		throw new Error('useStateValue must be used within a UserStateProvider');
	}
	return context;
};
