import '../Styles/HorizontalCard.css';
import musicNotes from '../Ressources/musicNotes.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import { saveAs } from 'file-saver';
import { PopupModalContext } from './PopupModal';
import { useUserStateValue } from './UserReducerProvider';

function HorizontalCard({
	track,
}: {
	track: {
		title: string;
		url: string;
		date: Date;
		from: string;
		artworkUrl: string | undefined;
	};
}) {
	const [downloading, setDownloading] = useState(false);

	const { openError } = useContext(PopupModalContext);
	const { state, dispatch } = useUserStateValue();

	const options = [
		{ value: 'low', text: 'Low' },
		{ value: 'medium', text: 'Medium' },
		{ value: 'high', text: 'High' },
	];

	const [qualitySelection, setQualitySelection] = useState(options[2].value);

	const download = async () => {
		if (!state.logged) {
			openError('Tu dois être connecté pour pouvoir télécharger une musique');
			return;
		}
		if (state.user!.limit <= 0) {
			openError(
				"Vous n'avez plus de crédit, mettez à jour votre plan pour pouvoir continuer à télécharger",
			);
			return;
		}

		setDownloading(true);
		const xhr = new XMLHttpRequest();
		xhr.open(
			'GET',
			`${process.env.REACT_APP_BACKEND}/song/download?` +
				new URLSearchParams({
					url: track.url,
					quality: qualitySelection,
				}),
			true,
		);
		xhr.setRequestHeader('Authorization', `Bearer ${state.accessToken}`);
		xhr.responseType = 'blob';
		xhr.onload = function () {
			const blob = xhr.response;
			saveAs(blob, `${track.title} • ${state.user?.id}.mp3`);
			dispatch({ type: 'DOWNLOAD' });
			setDownloading(false);
		};
		xhr.send();
	};

	const formatDate = (dateString: Date) => {
		const date = new Date(dateString);
		const year = date.getUTCFullYear();
		const month = String(date.getUTCMonth() + 1).padStart(2, '0');
		const day = String(date.getUTCDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	const formattedDate = formatDate(track.date);

	return (
		<div className="horizontal-card">
			<div className="horizontal-card--body">
				<div className="horizontal-card--image">
					<img
						src={track.artworkUrl ?? musicNotes}
						alt="musicNotes"
						className="music-icon"
					/>
				</div>
				<div className="horizontal-card--content">
					<h3 className="horizontal-card--content--title">{track.title}</h3>
					<a
						href={track.url}
						className="horizontal-card--content--url"
						target="_blank"
						rel="noopener noreferrer"
					>
						{track.url}
					</a>
				</div>
			</div>
			<div className="horizontal-card--footer">
				<p className="horizontal-card--date">{formattedDate}</p>
				<div
					style={{
						flexFlow: 'row',
						justifyContent: 'flex-end',
						flexWrap: 'wrap',
					}}
				>
					<select
						name="quality"
						id="quality"
						value={qualitySelection}
						onChange={(e) => setQualitySelection(e.target.value)}
						className="quality-selector"
						style={{ width: '100px', marginRight: '10px' }}
						disabled={track.from === 'sc'}
					>
						{options.map((option) => (
							<option key={option.value} value={option.value}>
								{option.text}
							</option>
						))}
					</select>
					<button
						style={{ marginRight: '10px' }}
						className={`download ${downloading ? 'downloading' : ''}`}
						onClick={() => download()}
					>
						<FontAwesomeIcon
							className={downloading ? 'downloading-reverse' : ''}
							icon={faDownload}
						/>
					</button>
				</div>
			</div>
		</div>
	);
}

export default HorizontalCard;
