import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CancelPage from './Components/Cancel';
import Homepage from './Components/Homepage';
import { Login } from './Components/Login';
import { Navbar } from './Components/Navbar';
import PlaylistPage from './Components/PlaylistPage';
import { PopupModalProvider } from './Components/PopupModal';
import Premium from './Components/Premium';
import SuccessPage from './Components/Success';
import { useUserStateValue } from './Components/UserReducerProvider';
import './Styles/App.css';
import PwdResetRequest from './Components/PwdResetRequest';
import PwdReset from './Components/PwdReset';
import DownloadHistory from './Components/DownloadHistory';
import Loader from './Components/Loader';
('./Components/UserReducerProvider');

function App() {
	const { state } = useUserStateValue();

	return (
		<PopupModalProvider>
			<div className="App">
				{!state.loaded ? (
					<Loader />
				) : (
					<>
						{state.logged ? (
							<React.Fragment>
								<Navbar />
								<Routes>
									<Route path="/" element={<Homepage />} />
									<Route path="/premium" element={<Premium />} />
									<Route path="/premium/success" element={<SuccessPage />} />
									<Route path="/premium/cancel" element={<CancelPage />} />
									<Route path="/playlist" element={<PlaylistPage />} />
									<Route path="/history" element={<DownloadHistory />} />
									<Route path="/password-reset" element={<PwdResetRequest />} />
									<Route path="/new-password" element={<PwdReset />} />
									<Route path="*" element={<Navigate to="/" />} />
								</Routes>
							</React.Fragment>
						) : (
							<Routes>
								<Route path="/" element={<Login />} />
								<Route path="/password-reset" element={<PwdResetRequest />} />
								<Route path="/new-password" element={<PwdReset />} />
								<Route path="*" element={<Navigate to="/" />} />
							</Routes>
						)}
					</>
				)}
			</div>
		</PopupModalProvider>
	);
}

export default App;
