import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PasswordService from '../Services/PasswordService';
import { PopupModalContext } from './PopupModal';
import '../Styles/PwdReset.css';

const PwdReset = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');

	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const { openSuccess, openError } = useContext(PopupModalContext);

	const navigate = useNavigate();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (newPassword !== confirmPassword) {
			openError('Les mots de passe ne correspondent pas!');
			return;
		}

		const data = { newPassword, token };
		const response = await PasswordService.resetPassword(data);

		if (response.ok) {
			openSuccess('Mot de passe réinitialisé avec succès!');
			navigate('/login');
		} else {
			openError('Erreur lors de la réinitialisation du mot de passe!');
		}
	};

	return (
		<div id="Reset" className="appear-animation">
			<h1>Changez votre mot de passe</h1>
			<form onSubmit={handleSubmit} className="pwd-form">
				<input
					className="pwd-input"
					type="password"
					placeholder="Nouveau mot de passe"
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
					required
				/>
				<input
					className="pwd-input"
					type="password"
					placeholder="Confirmez le mot de passe"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					required
				/>
				<button className="lgn-btn" type="submit">
					Reset Password
				</button>
			</form>
		</div>
	);
};

export default PwdReset;
