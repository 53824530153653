import { User } from '../Types/User';

export async function getUser() {
	try {
		const token = localStorage.getItem('token');
		if (!token) return;

		const response = await fetch(
			`${process.env.REACT_APP_BACKEND}/authentication/profile`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);

		if (!response.ok) {
			throw new Error(`Erreur HTTP: ${response.status}`);
		}

		const data = (await response.json()) as User;
		return data;
	} catch (error) {
		throw error;
	}
}
