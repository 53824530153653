class PasswordService {
	public async sendResetEmail(email: string): Promise<void> {
		const url = `${process.env.REACT_APP_BACKEND}/user/request-password-reset`;
		const dataToSend = {
			email: email,
		};

		await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(dataToSend),
		});
	}

	public async resetPassword(data: {
		newPassword: string;
		token: string | null;
	}): Promise<Response> {
		const url = `${process.env.REACT_APP_BACKEND}/user/reset-password`;
		const dataToSend = {
			newPassword: data.newPassword,
			token: data.token,
		};

		const res = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(dataToSend),
		});

		return res;
	}
}

export default new PasswordService();
