import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Track } from '../Types/Track';
import youtubeLogo from '../Ressources/youtube.png';
import soundcloudLogo from '../Ressources/soundcloud.jpg';
import '../Styles/TrackCard.css';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useContext, useState } from 'react';
import { saveAs } from 'file-saver';
import { useUserStateValue } from './UserReducerProvider';
import { PopupModalContext } from './PopupModal';

function TrackCard({ track }: { track: Track }) {
	const [showPreview, setShowPreview] = useState(false);
	const [downloading, setDownloading] = useState(false);

	const { openError } = useContext(PopupModalContext);
	const { state, dispatch } = useUserStateValue();

	const options = [
		{ value: 'low', text: 'Low' },
		{ value: 'medium', text: 'Medium' },
		{ value: 'high', text: 'High' },
	];
	const [qualitySelection, setQualitySelection] = useState(options[2].value);

	const download = async () => {
		if (!state.logged) {
			openError('Tu dois être connecté pour pouvoir télécharger une musique');
			return;
		}
		if (state.user?.role === 'free' && state.user?.limit <= 0) {
			openError(
				"Vous n'avez plus de crédit, mettez à jour votre plan pour pouvoir continuer à télécharger",
			);
			return;
		}

		setDownloading(true);
		const xhr = new XMLHttpRequest();
		xhr.open(
			'GET',
			`${process.env.REACT_APP_BACKEND}/song/download?` +
				new URLSearchParams({
					url: track.url,
					quality: qualitySelection,
				}),
			true,
		);
		xhr.setRequestHeader('Authorization', `Bearer ${state.accessToken}`);
		xhr.responseType = 'blob';
		xhr.onload = function () {
			const blob = xhr.response;
			saveAs(blob, `${track.title} • ${track.user}.mp3`);
			dispatch({ type: 'DOWNLOAD' });
			setDownloading(false);
		};
		xhr.send();
	};

	return (
		<div className="track-card appear-animation">
			<div className="header">
				<img
					src={track.from === 'sc' ? soundcloudLogo : youtubeLogo}
					className="source-icon"
				/>
				<h1 className="title">
					{track.title} • {track.user}
				</h1>
			</div>
			{showPreview ? (
				track.from === 'sc' ? (
					<iframe
						width="250px"
						height="140px"
						allow="autoplay"
						src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${track.id}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=true&visual=true`}
					></iframe>
				) : (
					<iframe
						width="250px"
						height="140px"
						allow="autoplay"
						src={`https://www.youtube.com/embed/${track.id}?autoplay=true`}
					></iframe>
				)
			) : (
				<div className="preview" onClick={() => setShowPreview(true)}>
					{track.from === 'sc' && (
						<img className="blurred" src={track.thumbnailUrl} />
					)}
					<img className="thumbnail" src={track.thumbnailUrl} />
					<img
						className="play-button"
						src={track.from === 'sc' ? soundcloudLogo : youtubeLogo}
					/>
				</div>
			)}
			<div className="footer">
				<select
					name="quality"
					id="quality"
					value={qualitySelection}
					onChange={(e) => setQualitySelection(e.target.value)}
					className="quality-selector"
					disabled={track.from === 'sc'}
				>
					{options.map((option) => (
						<option key={option.value} value={option.value}>
							{option.text}
						</option>
					))}
				</select>
				<button
					className={`download ${downloading ? 'downloading' : ''}`}
					onClick={() => download()}
				>
					<FontAwesomeIcon
						className={downloading ? 'downloading-reverse' : ''}
						icon={faDownload}
					/>
				</button>
			</div>
		</div>
	);
}

export default TrackCard;
