import { User, UserResponse } from '../Types/User';

class LoginService {
	login(email: string, password: string): Promise<UserResponse> {
		const url = `${process.env.REACT_APP_BACKEND}/user/login`;
		const dataToSend = {
			email: email,
			password: password,
		};
		return new Promise<UserResponse>(async (resolve, reject) => {
			const res = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// Autres en-têtes si nécessaire
				},
				body: JSON.stringify(dataToSend),
			});
			if (res.ok) {
				const data = (await res.json()) as UserResponse;
				localStorage.setItem('token', data.access_token);

				resolve(data);
			} else {
				reject(await res.json());
			}
		});
	}

	googleLogin = async (email: string, name: string) => {
		const url = `${process.env.REACT_APP_BACKEND}/user/googleLogin`;

		const dataToSend = {
			email: email,
			name: name,
		};
		return new Promise<UserResponse>(async (resolve, reject) => {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(dataToSend),
			});
			if (response.ok) {
				const data = (await response.json()) as UserResponse;
				localStorage.setItem('token', data.access_token);

				resolve(data);
			} else {
				reject(await response.json());
			}
		});
	};

	register(username: string, password: string, email: string): Promise<User> {
		const url = `${process.env.REACT_APP_BACKEND}/user/create`;
		const dataToSend = {
			username: username,
			password: password,
			email: email,
		};

		return new Promise<User>(async (resolve, reject) => {
			const res = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					// Autres en-têtes si nécessaire
				},
				body: JSON.stringify(dataToSend),
			});
			if (res.ok) {
				const data = (await res.json()) as User;
				resolve(data);
			} else {
				reject(await res.json());
			}
		});
	}
}

export default new LoginService();
