import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles//index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserStateProvider } from './Components/UserReducerProvider';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

root.render(
	<GoogleOAuthProvider clientId={clientId ?? ''}>
		<BrowserRouter>
			<UserStateProvider>
				<App />
			</UserStateProvider>
		</BrowserRouter>
	</GoogleOAuthProvider>,
);

reportWebVitals();
