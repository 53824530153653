import type { User, UserResponse } from './User';

export type UserAction =
	| { type: 'LOGIN'; payload: UserResponse }
	| { type: 'LOGOUT' }
	| { type: 'DOWNLOAD' }
	| { type: 'LOADED' };

export interface UserState {
	logged: boolean;
	user: User | undefined;
	accessToken: string | undefined;
	loaded: boolean;
}

export const initialUserState: UserState = {
	logged: false,
	user: undefined,
	accessToken: undefined,
	loaded: false,
};
