import React, { useState, useEffect } from 'react';
import '../Styles/Premium.css';
import { useUserStateValue } from './UserReducerProvider';
import musicNote from '../Ressources/premium-note.jpg';

function Premium() {
	const [userRole, setUserRole] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const { state, dispatch } = useUserStateValue();

	useEffect(() => {
		const fetchUserRole = async () => {
			try {
				const user = state.user;
				if (!user) throw new Error('Not logged');
				setUserRole(user.role);
				setError(null);
			} catch (error) {
				setError(error as any);
			} finally {
				setIsLoading(false);
			}
			const script = document.createElement('script');

			script.src = 'https://js.stripe.com/v3/pricing-table.js';
			script.async = true;

			document.body.appendChild(script);
		};

		fetchUserRole();
	}, [state.logged]);

	const sendRequest = async () => {
		try {
			const user = state.user;
			if (!user) throw new Error('Identifiant utilisateur non trouvé');
			const userId = user.id;

			const response = await fetch(
				`${process.env.REACT_APP_BACKEND}/payment/create?userId=${userId}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);

			if (!response.ok) {
				throw new Error(`Erreur HTTP: ${response.status}`);
			}

			const urlResponse = await response.text();
			console.log(urlResponse);
			window.location.href = urlResponse;
		} catch (error) {
			console.error(`Erreur in sendRequest: ${error}`);
		}
	};
	const isPremium = userRole === 'premium';
	if (isLoading) {
		return null;
	}
	if (error) {
		return <div className="content-message error">Une erreur est apparue</div>;
	}
	return (
		<div className="premium-card">
			<h1>Vivez au rythme de la musique, sans limite</h1>
			<div className="card-body">
				<img src={musicNote} alt="Music note" />

				<p className="credit-text">
					{isPremium ? (
						'Vous êtes déjà abonné à notre service premium, merci pour le soutien !'
					) : (
						<>
							Il vous reste{' '}
							<span className="credit-count">{state.user?.limit}</span> crédit
							{state.user!.limit > 1 ? 's' : ''}, et si vous ne vous souciez
							plus de ce nombre ?{' '}
						</>
					)}
				</p>
			</div>

			<button
				className="premium-btn"
				onClick={sendRequest}
				disabled={isPremium}
			>
				Passer à l'abonnement premium
			</button>
		</div>
	);
}

export default Premium;
