import HorizontalCard from './HorizontalCard';
import '../Styles/HorizontalCard.css';
import { useUserStateValue } from './UserReducerProvider';

function DownloadHistory() {
	const { state, dispatch } = useUserStateValue();

	const history = state.user!.history.sort(
		(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
	);

	return !state.logged ? (
		<h1>Connectez-vous pour voir votre historique de téléchargement</h1>
	) : state.user!.history.length === 0 ? (
		<h1>Aucun historique de téléchargement</h1>
	) : (
		<div style={{ marginTop: '5dvh' }}>
			{history.map((track, index) => (
				<div className="horizontal-card--parent appear-animation" key={index}>
					<HorizontalCard track={track} />
				</div>
			))}
		</div>
	);
}

export default DownloadHistory;
