import React from 'react';
import { useNavigate } from 'react-router-dom';

function CancelPage() {
	const navigate = useNavigate();

	return (
		<div className="cancel-page">
			<h1>Paiement annulé</h1>
			<p>La transaction a été annulée. Vous n'avez pas été débité.</p>
			<button className="btn" onClick={() => navigate('/')}>
				Retour à l'accueil
			</button>
		</div>
	);
}

export default CancelPage;
